<template>
  <Anonymous>
    <b-overlay :show="showOverlay" rounded="sm">
      <div class="form">
        <div class="row">
          <div class="col-sm-12">
            <div class="title">Password reset</div>
          </div>
          <div class="col-sm-12 mt-1">
            <div class="sub-title">
              Please enter a new password for your account
            </div>
          </div>
          <div class=" col-sm-12 alert alert-danger" role="alert" v-if="hasError">
            The reset token has not been found.
          </div>
          <div class="col-sm-12" v-if="!hasError">
            <b-form @submit="onSubmit" class="mt-4">
              <div role="group" class="form-group-input mt-4">
                <label for="password=reset-password">New password</label>
                <b-form-input
                    id="password=reset-password"
                    v-model="form.password"
                    autocomplete="current-password"
                    :type=inputPasswordType
                    :state="validateState('password')"
                ></b-form-input>
                <div class="icon">
                  <b-icon icon="eye" font-scale="1.5" @click="togglePassword"></b-icon>
                </div>
                <b-form-invalid-feedback id="input-email-feedback">
                  <div v-if="!$v.form.password.minLength">Must not be at less 8 and more 255 characters</div>
                  <div v-else>
                    Password is incorrect
                  </div>
                </b-form-invalid-feedback>
              </div>
              <div role="group" class="form-group-input mt-4">
                <label>Confirm password</label>
                <b-form-input
                    id="password-confirm"
                    v-model="form.confirmPassword"
                    :type=inputConfirmPasswordType
                    :state="validateState('confirmPassword')"
                ></b-form-input>
                <div class="icon">
                  <b-icon icon="eye" font-scale="1.5" @click="toggleConfirmPassword"></b-icon>
                </div>
                <b-form-invalid-feedback>
                  <div v-if="!$v.form.confirmPassword.sameAsPassword">Password must be identical</div>
                </b-form-invalid-feedback>
              </div>
              <div class="mt-4 d-flex">
                <div class="flex-fill">
                  <b-button type="submit" variant="primary">Update & login</b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </b-overlay>

  </Anonymous>
</template>

<script>
import Anonymous from "../layouts/Anonymous";
import {validationMixin} from 'vuelidate';
import {required, maxLength, minLength, sameAs} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PasswordReset",
  components: {Anonymous},
  mixins: [validationMixin],
  mounted() {
    this.clear();
    this.checkResetToken(this.$route.params.token)
  },
  data() {
    return {
      form: {
        password: '',
        confirmPassword: ''
      },
      showPassword: false,
      showConfirmPassword: false,
      showOverlay: false
    }
  },
  validations: {
    form: {
      password: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(8)
      },
      confirmPassword: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["hasError", "email", "isAuthenticated"]),
    ...mapGetters('errors', ['hasErrors']),
    inputPasswordType: function () {
      if (this.showPassword) {
        return 'text';
      }
      return 'password';
    },
    inputConfirmPasswordType() {
      if (this.showConfirmPassword) {
        return 'text';
      }
      return 'password';
    },
  },
  methods: {
    ...mapActions("auth", ["checkResetToken", "passwordReset", "clear"]),
    onSubmit(event) {
      event.preventDefault()
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.showOverlay = true;
      this.passwordReset({
        email: this.email,
        password: this.form.password,
        token: this.$route.params.token
      }).then(() => {
        this.showOverlay = false;
      })
    },
    togglePassword() {
      if (this.showPassword) {
        this.showPassword = false;
      } else {
        this.showPassword = true;
      }
    },
    toggleConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    validateState(name) {
      if (this.hasErrors) {
        return false;
      }
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
}
</script>

<style scoped>

</style>